import { cn } from '#app/utils/misc'

export type SimpleHeaderProps = {
	title: string
	subtitle?: string | React.ReactNode
	className?: string
	children?: React.ReactNode
}

export default function HeaderSimple({
	title,
	subtitle,
	className,
	children,
}: SimpleHeaderProps) {
	return (
		<header
			className={cn(
				'flex flex-row gap-x-5 border-b bg-white px-5 py-3',
				className,
			)}
		>
			<div className="flex flex-grow flex-col gap-x-5 overflow-hidden">
				<div className="text-xl font-semibold">{title}</div>
				{subtitle && (
					<div className="truncate text-sm text-text-gray">
						{subtitle}
					</div>
				)}
			</div>
			{children}
		</header>
	)
}
